/* @import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Edu+AU+VIC+WA+NT+Pre:wght@400..700&family=Protest+Revolution&family=Quicksand:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Mooli&family=Outfit:wght@100..900&family=Parkinsans:wght@300..800&family=Pixelify+Sans&family=Poppins:wght@100;400&family=Raleway:ital,wght@0,100..900;1,100..900&family=Whisper&family=Young+Serif&display=swap');

* {
  /* font-family: "Protest Revolution", sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.5px; */
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}





html {
  scroll-behavior: smooth;
}




/* Top Bar Styles */
.top-bar {
  background-color: #004AB1;
  color: rgb(255, 255, 255);
  font-size: 20px;
  padding: 15px 20px;

}

.top-bar .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  /* Enables wrapping for smaller screens */
}

.top-bar span {
  margin: 5px 0;
  /* Adds spacing between items when wrapped */
}

/* Icon Spacing */
.top-bar .icon {
  margin-right: 5px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .top-bar {
    font-size: 16px;
    /* Reduce font size for smaller screens */
    padding: 10px 15px;
    /* Adjust padding for smaller screens */
  }

  .top-bar .container {
    flex-direction: column;
    /* Stack items vertically */
    text-align: center;
    /* Center align for better appearance */
  }

  .top-bar span {
    margin: 10px 0;
    /* Increase spacing between stacked items */
  }
}

@media screen and (max-width: 480px) {
  .top-bar {
    font-size: 14px;
    /* Further reduce font size for very small screens */
    padding: 8px 10px;
  }
}


/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #fcfbfc;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(5, 21, 196, 0.1);
  height: 100px;
  /* Ensure navbar has a fixed height */
}

.navlogoimg {
  width: 170px;
  height: 170px;
  margin-top: -45px;
  margin-left: 40px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  align-items: center;
  height: 100%;
  /* Match navbar height */
}

.nav-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 15px;
  font-size: 1.3rem;
}

.nav-links li a {
  color: #004ab1;
  text-decoration: none;
  display: flex;
  align-items: center;

}

.nav-links li a svg {
  margin-right: 8px;
  font-size: 1.3rem;
  color: orangered;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 20px;
  background-color: transparent;
}

.hamburger .bar {
  width: 25px;
  height: 4px;
  background-color: orangered;
  border-radius: 5px;
}

/* Show the menu when the hamburger icon is clicked */
.nav-links.active {
  display: flex !important;
}

/* Navbar styling for desktop */
.nav-links {
  display: flex;
  align-items: center;
}

.hamburger {
  display: none;
}

/* Tablet and Mobile View */
@media (max-width: 930px) {
  .nav-links {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    /* White background */
    width: 100%;
    height: calc(100vh - 100px);
    /* Adjust height for mobile */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .nav-links.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .nav-links ul {
    display: block;
    margin: 0;
    line-height: 2.5;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 100px);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 3;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links ul {
    display: block;
    margin: 0;
    margin-top: -170px;
    line-height: 4;
  }

  .hamburger {
    display: flex;
  }
}


/* ....................................................H O M E ................................................... */

/* General styling for the Home component */
.home {
  font-family: Arial, sans-serif;
}



/* Carousel */
.carousel-section img {
  max-height: 630px;
  object-fit: cover;
}

.caption {
  text-shadow: 5px 5px 2px#1a1a1a;
  font-size: 50px;
}


@media (max-width:380px) {

  .carousel-section img {
    max-height: 700px !important;
    object-fit: cover;
  }
}


/* .............................................about................................................... */

/* About Us Section Container */
.about-section {
  padding: 50px 20px;
  background-color: white;
  text-align: center;
}

/* Section Heading */
.section-heading {
  font-size: 2.5rem;
  color: #108cc1;
  margin-bottom: 0px;
  font-weight: bold;
}

/* About Content Container */
.about-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Add spacing between rows */
}

/* About Row (Text and Image) */
.about-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 20px;
}

/* Reverse Layout for Alternate Rows */
.about-row.reverse {
  flex-direction: row-reverse;
  margin-top: -130px;
}

/* About Text */
.about-text {
  flex: 1;
  max-width: 600px;
  font-size: 2rem !important;
  line-height: 1.8;
  color: #333;
  padding: 0 20px;
}

.about-p {
  font-size: 1.2rem;
}

/* About Image */
.about-image {
  flex: 1;
  max-width: 400px;
  text-align: center;
}

.about-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}

/* New Section: Flexible Online Classes & Training Programs */
.about-row.new-section {
  background-color: #eaf4f7;
  padding: 40px;
}

.about-row.new-section .about-text h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-row.new-section .about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 0px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-row {
    flex-direction: column;
  }

  .about-row.reverse {
    flex-direction: column;
    /* Reverse rows stack vertically */
  }

  .about-text {
    text-align: center;
    padding: 0 20px;
    font-size: 1rem !important;
  }

  .about-image {
    margin-top: -50px;
  }

  .section-heading {
    font-size: 2rem;
    /* Adjust the size for small screens */
  }

  .about-text {
    font-size: 1rem;
    /* Adjust font size for better readability */
  }
}

@media (max-width:380px) {
  .about-text {
    font-size: 1rem !important;
  }

}

/* .....................................VideoSection.css ..............................*/

.video-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;
}

.section-heading {
  font-size: 2.5rem;
  color: #004AB1;
  font-weight: bold;
  margin-bottom: 1rem;
}

.video-section {
  position: relative;
  width: 100%;
  height: 80vh;
  /* Adjust height as needed */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.b1 {
  position: relative;
  top: -100px;
  left: 150px;
  width: 600px;
  margin-bottom: 40px;
}

.b2 {
  position: relative;
  top: 160px;
  right: 170px;
  width: 800px;
  margin-bottom: 40px;
}

.video-bg {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  background: none;
  object-fit: contain;
  /* Ensure the entire video fits within the container */
  object-position: center;
  /* Center the video within the container */

}

.register-btn {
  background-color: #004AB1;
  color: white;
  padding: 20px 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 20px;
  box-shadow: 2px 2px 4px #1f1f1f;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 30px;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  text-decoration: none !important;
}

.register-btn:hover {
  text-decoration: none;
  transform: scale(1.1);
}

.register-btn-Link {
  text-decoration: none !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .video-section {
    height: 50vh;
    /* Reduce height for smaller screens */
  }

  .video-section {
    background: white;
  }

  .section-heading {
    font-size: 2rem;
  }

}

@media (max-width: 480px) {
  .section-heading {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  .video-section {
    background: white;
    margin-top: -30px !important;
  }

  .video-section {
    height: 25vh;
  }
}

/* ...................................why choose us?................................. */

.y-us-section {
  padding: 50px 0;
  background: #f8f9fa;
}

.y-us-head {
  text-align: center;
  margin-bottom: 40px;
}

.y-us-head h2 {
  font-size: 36px;
  font-weight: 600;
  color: #004AB1;
  text-transform: uppercase;
}

.y-us-head p {
  color: black;
  font-size: 18px;
  line-height: 3;
}

.y-us-title {
  font-size: 24px !important;
  color: #004AB1 !important;
}

.y-us-title-border {
  display: block;
  width: 50px;
  height: 4px;
  background: orangered;
  margin: 20px auto;
}

.service-box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.iconset {
  margin-bottom: 15px;
}

.icon {
  font-size: 40px;
  color: orangered;
}

.y-us-content h4 {
  font-size: 20px;
  color: #004AB1;
  margin-bottom: 10px;
  font-weight: bold;
}

.y-us-content p {
  color: #535454;
  font-size: 14px;
  line-height: 22px;
}

@media (max-width: 768px) {
  .service-box {
    margin-bottom: 20px;
  }
}


/*........................................ Testimonial Container...................................... */


.testimonial-container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  height: 700px !important;
  /* Increased height of the container */
  overflow: hidden;
  /* To ensure content doesn't overflow */
}

/* Swiper container and slide styling */
.testimonialSwiper {
  height: auto;
  padding: 20px 10px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  /* Smooth transition for hover effect */
}

.swiper-slide:hover {
  transform: scale(1.05);
  /* Zoom effect when hovered */
}

.swiper-slide div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
  /* Increased padding for better spacing */
  box-shadow: 0px 4px 6px rgba(2, 31, 69, 0.1);
  border-radius: 10px;
  background-color: white;
  height: 100%;
  justify-content: center;
}

.swiper-slide img {
  width: 250px;
  /* Adjusted to desired size */
  height: 250px;
  /* Adjusted to desired size */
  object-fit: cover;
  /* Ensures the image covers the allocated space without distortion */
  border-radius: 10px;
  /* Circle shape for the image */
  margin-bottom: 20px;
  /* Increased margin to give more space */
}

.swiper-slide p {
  font-style: italic;
  margin-bottom: 15px;
  font-size: 20px;
}

.swiper-slide h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #004AB1 !important;
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
  .testimonial-container {
    width: 100%;
    padding: 10px 0;
    height: auto;
    /* Allow the container to adjust height based on content */
  }

  .swiper-slide div {
    padding: 20px;
  }

  .swiper-slide img {
    width: 90px;
    /* Slightly smaller image for tablet */
    height: 90px;
  }

  .swiper-slide h3 {
    font-size: 16px;
    /* Smaller font size for name */
  }

  .swiper-slide p {
    font-size: 14px;
    /* Smaller font size for feedback */
  }
}

/* Responsive Design for Small Mobile Screens */
@media (max-width: 480px) {
  .testimonial-container {
    width: 100%;
    padding: 10px 0;
    height: auto;
    /* Allow the container to adjust height based on content */
    margin-bottom: 0px;
  }

  .swiper-slide div {
    padding: 25px;
    height: 400px;
  }

  .swiper-slide img {
    width: 100px;
    /* Even smaller image for very small screens */
    height: 100px;
    margin-bottom: 40px;
  }

  .swiper-slide h3 {
    font-size: 20px;
    margin-bottom: 20px;
    /* Even smaller font size for name */
  }

  .swiper-slide p {
    margin-bottom: 20px;
    font-size: 17px;
    /* Even smaller font size for feedback */
  }
}


/* ................................................FAQ SECTION........................................ */
.faq-section {
  padding: 30px;
  background-color: white;
  margin-bottom: 20px !important;
  max-width: 800px;
  /* Adjust this width to your preference */
  margin: 0 auto;
  /* Centers the section */
}

.faq-section h2 {
  margin: auto;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: #004AB1;
}

.faq-list {
  margin-top: 30px;

}

.faq-item {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: all 0.1s ease;
}

.faq-item:hover {
  transform: scale(1.01);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  margin: 0;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 1.1rem;
  color: orangered;
}

.faq-item span {
  font-size: 2rem;
  /* Increased size of the plus/minus sign */
  font-weight: bold;
  color: #004AB1;
  /* Optional: gives the +/− a color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-section {
    max-width: 100%;
    /* Take full width on smaller screens */
    padding: 20px;
    /* Reduce padding on smaller screens */
  }

  .faq-section h2 {
    font-size: 24px;
    /* Slightly smaller heading font on smaller screens */
  }

  .faq-item {
    padding: 12px;
    /* Reduce padding for better readability */
  }

  .faq-question h3 {
    font-size: 1.1rem;
    /* Adjust font size for smaller screens */
  }

  .faq-answer {
    font-size: 1rem;
    /* Adjust answer font size */
  }

  .faq-item span {
    font-size: 1.8rem;
    /* Slightly smaller plus/minus sign on smaller screens */
  }
}

@media (max-width: 480px) {
  .faq-section h2 {
    font-size: 20px;
    /* Even smaller heading font for very small screens */
  }

  .faq-item {
    padding: 10px;
    /* Further reduce padding */
  }

  .faq-question h3 {
    font-size: 1rem;
    /* Adjust font size for very small screens */
  }

  .faq-answer {
    font-size: 0.95rem;
    /* Further adjust font size */
  }

  .faq-item span {
    font-size: 1.6rem;
    /* Even smaller plus/minus sign */
  }
}

/* ...............................FOOTER...................................... */

/* Footer Styles */

.footer {
  margin-top: -70px;

}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

}

.footer-logo {
  width: 300px;
  height: 300px;
  margin-left: 10px;
  font-weight: bold;
  color: white;
  margin-bottom: -130px;
  border-radius: 180px;
  margin-top: 10px;
  font-family: 'Arial', sans-serif;
}

.brand-description {
  font-size: 1.3rem;
  margin: 10px 0 30px;
  color: #f4986a;

}

.hindides {
  margin-top: -90px;
  line-height: 2;
}

/* Footer Sections */
/* Footer container */
.footer {
  margin-top: -70px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  text-align: center;
}

.footer-logo {
  width: 300px;
  height: 300px;
  margin-left: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: -130px;
  border-radius: 180px;
  margin-top: 10px;
  font-family: 'Arial', sans-serif;
}

.hindides {
  margin-top: -90px;
  line-height: 2;
}

/* Footer Container */
footer {
  background-color: white;
  color: #004AB1;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

.footer-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  margin-top: 30px;
}

.footer-section {
  max-width: 300px;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.social-icons a {
  font-size: 1.5rem;
  margin: 0 8px;
  color: orangered;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: rgb(234, 118, 76);
}

.important-links ul {
  list-style: none;
  padding: 0;

}

.important-links li {
  margin: 10px 0;
  font-size: 1rem;
}

.important-links a {
  color: orangered;
  text-decoration: none;
}

.important-links a:hover {
  color: rgb(237, 129, 90);
}

/* Newsletter Form */
.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-p {
  color: orangered !important;
}

.newsletter-form input[type="email"] {
  padding: 8px;
  width: 80%;
  margin-bottom: 10px;
  border-radius: 4px;
  border: none;
  border-bottom: 2px solid orangered;
}

.newsletter-form button {
  background-color: #004AB1;
  color: white;
  padding: 8px 16px;
  width: 200px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.newsletter-form button:hover {
  background-color: #4a94fc;
}



/* Responsiveness for Smaller Screens */
@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
    align-items: center;

  }

  .social-icons {
    margin-bottom: 20px;
  }

  .footer-content {
    margin-top: -130px;
  }
}

@media (max-width: 328px) {
  .footer-logo {
    margin-left: -40px !important;
    width: 200px;
    height: 200px;
  }

  .footer {
    margin-right: -30px !important;
  }

  .footer-content {
    margin-top: -130px;
  }
}



/* ,,,,,,,,,,,,,,,..............,,courses page,................,,,,,,,,,,,,,,,,,,,,,,,, */

/* course Page */
.course-page {
  padding: 20px;
}

.courseimg {
  width: 400px;
  margin-top: -80px;
  height: 400px;
}

.courseheading {
  color: #004AB1;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}



@media(max-width:768px) {
  .courseimg {
    width: 300px;
    margin-top: -80px;
    height: 300px;
    margin-right: -30px;
  }

  .courseheading {
    margin-left: 50px;
    margin-bottom: 10px !important;

  }
}

/* ...................................CONTACT........................................... */

/* Contact Form Container */
.contact-form-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px !important;
  align-items: center;
  margin-top: 2rem;
  padding: 2rem;
  background: #004AB1;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 50px;
  gap: 25px;
  flex-wrap: wrap;
  /* Allow wrapping for smaller screens */
}

/* Contact Info Card */
.contact-info-card {
  width: 48%;
  /* 50% width to give space for form */
  padding: 1.5rem;
  background-color: white;
  /* Light background for the contact info card */
  border-radius: 8px;
}

.contact-video-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.contact-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  /* Ensures video covers the area */
}

/* Contact Form Card */
.contact-card {
  width: 48%;
  /* 50% width for form to sit next to video */
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form Styling */
.contact-card h2 {
  font-size: 2rem;
  font-weight: bolder;
  color: orangered;
  text-align: center;
}

.contact-input-group {
  margin-bottom: 2rem;
}

.contact-input-group input,
.contact-input-group textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  border-top: 2px solid orangered;
  border-bottom: 2px solid orangered !important;
}

.contact-input-group input:focus,
.contact-input-group textarea:focus {
  outline: none;
  border: 1px solid orangered;
  border-top: 2px solid orangered;
  border-bottom: 2px solid orangered !important;
}

.contact-input-group textarea {
  height: 150px;
  resize: none;
}

/* Submit Button */
.contact-submit-btn {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: -250px !important;
}

.contact-submit-btn:hover {
  background-color: rgb(240, 181, 71);
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
    /* Stack the form and video card */
    padding: 1rem;
  }

  .contact-info-card,
  .contact-card {
    width: 100%;
    /* Full width for both video and form */
    margin-bottom: 2rem;
  }

  .contact-video {
    height: 300px;
    /* Limit the video height on smaller screens */
    object-fit: cover;
    /* Ensure video maintains aspect ratio */
  }

  /* Adjust padding and margins for smaller screens */
  .contact-card {
    padding: 1.5rem;
  }

  .contact-submit-btn {
    font-size: 1.1rem;
  }

  .contact-input-group input,
  .contact-input-group textarea {
    padding: 0.9rem;
    /* Increase padding for better touch targets */
  }
}

/* Company Info Box */
.company-info-box {
  margin-top: 2rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Flexbox Layout for Content */
.company-info-content {
  display: flex;
  align-items: center;
  /* Vertically align items */
  justify-content: space-between;
  /* Space between image and text */
  gap: 2rem;
  /* Space between the two sections */
}

/* Image Styling */
.company-image {
  width: 40%;
  /* Occupies 40% of the width */
  height: 450px;
  border-radius: 10px;
  object-fit: cover;
}

/* Text Section Styling */
.company-text {
  width: 55%;
  /* Occupies 55% of the width */
}

.company-text h3 {
  font-size: 2rem;
  color: #004AB1;
  margin-bottom: 1.5rem;
}

.company-text p {
  font-size: 1.2rem;
  color: #333;
  margin: 0.5rem 0;
}

.company-text .contact-info {
  font-weight: bold;
  color: #004AB1;
}

/* Tilt Effect on Hover */
.company-info-box:hover {
  transform: rotateY(4deg) rotateX(4deg);
  /* Tilt effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  /* Enhance shadow on hover */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .company-info-content {
    flex-direction: column;
    /* Stack image and text vertically */
    align-items: center;
    /* Center align */
    text-align: center;
  }

  .company-image {
    width: 100%;
    /* Full-width image */
    margin-bottom: 1.5rem;
  }

  .company-text {
    width: 100%;
    /* Full-width text */
  }
}

/* ..................................................INSTRUCTORS................................................ */


/* Custom Styling for Instructors */
.instructor-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.9);

}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.inst-heading {
  font-size: 1.9rem;
  font-weight: bold;
  color: #004AB1;
}

.card-body {
  background-color: none;
  border-radius: 10px;
  color: #333;
  font-weight: lighter;
  margin-bottom: 30px !important;

}

.card-text {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;


}

.card:hover {
  transform: scale(1.05);
  transition: 0.3s ease;
}

.inst-btn {
  background-color: orangered !important;
  color: white !important;
  margin-left: 20px;
}

/* Icons Section */
.icons-section-container {
  background-color: none;
  padding: 30px 15px;
  /* Add padding for smaller screens */
}

.icons-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center aligns content for all screen sizes */
  gap: 20px;
  padding: 20px;
}

.icon-card {
  text-align: center;
  padding: 15px;
  width: 130px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(2, 32, 109, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
}

.icon-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 12px rgba(156, 84, 7, 0.5);
}

.icon-card img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.icon-card p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: orangered;
}

/* Responsiveness for Icons Section */
@media (max-width: 768px) {
  .icon-card {
    width: 45%;
    /* Adjust width to fit two icons per row */
    margin: auto;

  }

  .icon-card img {
    width: 80px;
    /* Reduce icon size */
    height: 80px;
  }

  .icon-card p {
    font-size: 12px;
  }

  .inst-heading {
    font-size: 2rem;
    font-weight: bold;
    color: #004AB1;

  }

  .card {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .icon-card {
    width: 200px;
    /* Stack icons vertically */
    margin-bottom: 20px !important;
    /* Add space between stacked items */
    margin: auto;

  }

  .icon-card img {
    width: 70px;
    height: 70px;
  }

  .icon-card p {
    font-size: 11px;
  }
}

/* Book Image */
.bookimg {
  height: 300px !important;
  width: 600px;
  margin-top: -60px;
  max-width: 100%;
  /* Ensures the image does not exceed its container width */
  height: auto;
  /* Maintains the aspect ratio */
}

@media (max-width: 768px) {
  .bookimg {
    height: 100px;
    /* Smaller height for medium screens */
    width: auto;
    /* Adjust width proportionally */
    margin-top: -40px !important;
  }
}

@media (max-width: 576px) {
  .bookimg {
    height: 130px !important;
    /* Even smaller height for small screens */
    width: auto;
    /* Adjust width proportionally */
    margin-top: -70px;
  }
}

/* .............................................  INSTRUCTOR DETAILS PAGE ........................................................ */


/* Body Styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Container */
.container.detail-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

/* Instructor Card */
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image Container */
.image-container {
  width: 100%;
  height: auto;
  /* Set fixed height for the square image */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
}

.image-container img {
  width: 150px;
  /* Width and height set to create a square shape */
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  /* Optional, for rounded corners */
}

/* Title */
h3.card-title {
  font-size: 2rem;
  color: #004AB1;
  margin-bottom: 20px;
  text-align: center;
}

/* Subheading */
p.card-text {
  font-size: 1.2rem;
  color: #555555;
  line-height: 2;
}

a {
  text-decoration: none !important;
}

/* Skills List */
.skills h4 {
  font-size: 2rem;
  color: #004AB1;
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;

}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1rem;
  color: #333333;
  padding: 5px 0;
}

.skills ul li::before {
  content: "✔️";
  margin-right: 8px;
  color: #108cc1;
}

.skill-name {
  font-size: 1.3rem;
  color: orangered;
}

/* Journey Section */
.journey-item {
  margin-bottom: 20px;
}

.journey h4 {
  font-size: 2rem;
  color: #004AB1;
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: center;
}

.journey h5 {
  font-size: 1.25rem;
  color: orangered !important;
  margin-bottom: 10px;
  font-weight: bold;
}

.journey p {
  font-size: 1rem;
  line-height: 2;
}

/* "Why Learn" Section */
.why-learn h4 {
  font-size: 2rem;
  color: #004AB1;
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: center;
}



.why-learn ul {
  list-style-type: none;
  padding: 0;
}

.why-learn ul li::before {
  content: "✔️";
  margin-right: 8px;
}

.why-learn li {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Back Button */
.text-center.mt-4 {
  margin-top: 40px;
}

.text-center.mt-4 .btn {
  background-color: #004AB1;
  color: #fff;
  text-decoration: none;
  margin-top: 50px;
}

.text-center.mt-4 .btn:hover {
  background-color: #457fe3;
}

/* Responsive Styles */

/* Medium Devices (tablets) */
@media (max-width: 768px) {
  .col-12 {
    width: 100%;
  }

  h3.card-title {
    font-size: 1.75rem;
  }

  p.card-text {
    font-size: 0.9rem;
  }
}

/* Small Devices (phones) */
@media (max-width: 576px) {
  .container.detail-page {
    padding: 10px;
  }

  .image-container {
    height: 150px;
    /* Smaller height for phones */
  }

  .image-container img {
    width: 100px;
    /* Smaller image for phones */
    height: 100px;
  }

  h3.card-title {
    font-size: 1.5rem;
  }

  p.card-text {
    font-size: 0.85rem;
  }

  .why-learn {
    padding: 15px;
  }

  .why-learn h4 {
    font-size: 1.25rem;
  }

  .why-learn ul li {
    font-size: 0.9rem;
  }
}


/* ..................................COURSES ................................................. */
.courses-cards {
  display: flex;
  gap: 50px;
  /* Reduced gap for a more compact layout */
  justify-content: center;
  flex-wrap: wrap;
}

.courses-product-card {
  width: 100%;
  max-width: 400px;
  /* Smaller max width for a more compact size */
  flex: 1 1 auto;
  padding: 15px;
  /* Adjust padding as needed */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(2, 34, 89, 0.3);
  transition: transform 0.3s ease-in-out;
  text-align: center;

}

.courses-product-card img {
  width: 100%;
  height: 260px;
  /* Smaller image height */
  object-fit: cover;
  border-radius: 8px;

}

.courses-product-card h5 {
  font-size: 1.1rem;
  /* Adjusted font size for titles */
  margin: 10px 0;
}

.product-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004AB1;

}

.courses-product-card p {
  font-size: 0.9rem;
  /* Smaller text for descriptions */
  color: #555;

}

.courses-product-card .price {
  font-size: 1rem;
  font-weight: bold;
}



.viewdetails-btn {
  background-color: orangered;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  text-decoration: none;

}

.viewdetails-btn:hover {
  background-color: rgb(232, 138, 88);
  color: white;
  text-decoration: none;
}

/* Ensure images are responsive on smaller screens */
@media (max-width: 768px) {
  .courses-product-card img {
    height: auto;
    /* Automatically adjust height to maintain aspect ratio */
  }
}









/* ...................................COURSE-DETAILS.................................... */
/* ...................................COURSE-DETAILS.................................... */
.course-page {
  padding: 40px 15px;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.course-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.course-image-section {
  text-align: center;
  margin-bottom: 30px;
  opacity: 0;
  animation: slideUp 1s ease-in-out forwards;
}

.course-image {
  max-width: 30%;
  /* Reduced the image size */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(0.8);
  /* Slightly smaller */
  animation: scaleUpSmall 1s ease-in-out forwards;
}

.course-price {
  font-size: 2rem;
  color: #ff6f61;
  margin-top: 15px;
}

.course-details-container {
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: fadeIn 1s 0.8s forwards;
  margin-bottom: 20px !important;
}

.course-heading {
  font-size: 3rem;
  color: #004AB1;
  margin-bottom: 20px;
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 1s 0.7s forwards;
}

.course-info-section {
  margin-bottom: 30px;
}

.course-options {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  opacity: 0;
  animation: slideUp 1s 0.5s ease-in-out forwards;
}

.option {
  padding: 10px 20px;
  border: none;
  border-bottom: 5px solid #ddd;
  border-radius: 5px;
  margin-left: 20px;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.option:hover {
  background-color: #004AB1;
  color: white;
}

.enroll-now-button {
  background-color: #004AB1;
  color: white;
  padding: 12px 30px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s 1s forwards;
}

.enroll-now-button:hover {
  background-color: #2b7bfc;
}

.course-syllabus ul {
  list-style-type: disc;
  margin-left: 30px;
  padding-left: 30px;
  opacity: 0;
  animation: fadeIn 1s 1.3s forwards;
}

.go-back-to-courses {
  color: #004AB1;
  font-weight: bold;
  text-decoration: none;
  margin-top: 50px;
  display: block;
  text-align: center;
  font-size: 2rem;
  opacity: 0;
  animation: fadeIn 1s 1.5s forwards;
}

.go-back-to-courses:hover {
  text-decoration: none;
  color: orangered;
}

hr {
  border: 1px solid !important;
  width: 800px;
  margin: 0 auto !important;
  margin-top: 30px !important;

}

.syllabus {
  margin-top: 30px;
  text-align: left;
}

.course-syllabus img {
  max-width: 100%;
  /* Adjusted size for image */
  height: auto;
  margin-top: 100px !important;
  transform: scale(0.1);
  /* Slightly smaller scaling */
  animation: scaleUpSmall 1s 1s ease-in forwards;
}

.course-syllabus {
  margin-top: 30px;
  text-align: left;
}

.syllabus-content {
  display: flex;
  justify-content: space-between;
  /* Left and right alignment */
  align-items: flex-start;
  gap: 30px;
  /* Space between list and image */
  flex-wrap: wrap;
  /* Wrap on smaller screens */
}

.syllabus-list {
  flex: 1;
  margin-top: 30px;
  line-height: 2;
  font-size: 20px;
}

.course-heading {
  font-size: 2.5rem;
  /* Reduce heading size */
  margin-bottom: 15px;
  text-align: center;
  /* Centered heading */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleUpSmall {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .course-image-section {
    margin-bottom: 20px;
  }

  .course-options {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  .option {
    width: 100%;
    max-width: 300px;
  }

  .enroll-now-button {
    width: 100%;
    max-width: 300px;
  }

  .course-price {
    font-size: 1.5rem;
  }

  .course-heading {
    font-size: 1.5rem;
  }

  .go-back-to-courses {
    font-size: 1rem;
  }

  /* Adjust syllabus layout */
  .syllabus-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .syllabus-list {
    width: 100%;
  }

  .syllabus-image {
    width: 100%;
    max-width: 400px;
    /* Limit image size on mobile */
    margin-top: 0px;
  }

  .course-syllabus img {
    max-width: 100%;
  }

  .course-heading {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .course-price {
    font-size: 1.2rem;
  }

  .course-details-container {
    padding: 15px;
  }

  .course-heading {
    font-size: 1.4rem;
  }

  .syllabus-image {
    width: 100%;
    max-width: 400px;
    /* Limit image size on mobile */
    margin-top: -160px !important;
  }

}


/* ................................................BLOG PAGE .................................................. */

.blog-page {
  padding: 30px;
}

.blog-title {
  text-align: center;
  font-size: 36px;
  color: #004AB1;
  margin-bottom: 30px;
}

.blog-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  text-align: center;
}

.blog-card-link {
  text-decoration: none;
  /* Remove text decoration */
  width: 30%;
  /* 3 cards in a row */
  transition: transform 0.2s ease-in-out;
}

.blog-card-link:hover {
  text-decoration: none;
  /* Ensure no text decoration on hover */
}

.blog-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 100%;
  transition: transform 0.2s ease;
  margin-top: 30px;

}

.blog-card:hover {
  transform: scale(1.05);
  /* Slightly enlarge the card on hover */
}

.blog-card-image {
  width: 100%;
  /* Make the image take full width of the card */
  height: 300px;
  /* Set a fixed height for the image */
  object-fit: cover;
  /* Ensure the image fits the container without distortion */
  border-radius: 8px;
}

.blog-card-content {
  margin-top: 50px;
}

.blog-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004AB1;
}

.blog-card-description {
  font-size: 1.1rem;
  color: #555;
  margin: 10px 0;
}

.blog-card-date {
  font-size: 1rem;
  color: orangered;
}

/* Media queries to make it responsive */
@media (max-width: 768px) {
  .blog-card-link {
    width: 48%;
    /* 2 cards in a row */
  }
}

@media (max-width: 480px) {
  .blog-card-link {
    width: 100%;
    /* 1 card in a row */
  }
}


@media only screen and (max-width: 930px) and (min-width: 200px) {
  .nav-links li a {
    color: #004ab1;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    top: 150px;
    background-color: white;
  }

}